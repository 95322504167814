import React from 'react';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from 'moment';

export default function Datepicker(props) {
  const { onChange, concept, value, filterDate, locale } = props;

  const handleChange = (date) => {
    onChange(concept, date);
  };

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale={locale}>
      <DatePicker
        value={value ? moment(value) : null}
        onChange={(date) => handleChange(date.toDate())}
        shouldDisableDate={(date) => !filterDate(date)}
        slotProps={{
          textField: {
            sx: {
              '&.MuiTextField-root': {width: '100%'},
              '.MuiInputBase-input': {fontSize: '14px'}
            }
          },
          calendarHeader: {
            sx: {
              '.MuiPickersCalendarHeader-label': {fontSize: '14px'},
            }
          },
          layout: {
            sx: {
              '.MuiDayCalendar-weekDayLabel': {fontSize: '12px'},
            }
          },
          day: {
            sx: {
              '&.MuiButtonBase-root': {fontSize: '12px'} 
            }
          }
        }}
      />
    </LocalizationProvider>
  )
}
