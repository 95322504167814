import React from 'react';
import PropTypes from 'prop-types';
import LuggageStep from './LuggageStep';
import Header from './Header';
import ContactStep from './ContactStep';
import StepButtons from './StepButtons';
import ShippingAddressStep from './ShippingAddressStep';
import DeliveryAddressStep from './DeliveryAddressStep';
import CheckYourDataStep from './CheckYourDataStep';
import StepIndicator from './StepIndicator';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';

function BookingForm(props){
  const { typesOfLuggage, extraLuggages, currentStep, steps, onClickNextStep,
    onChangeLuggageQuantity, selectedLuggages, countries, onChangeShippingAddress,
    shippingAddressData, onChangeContact, contactData, deliveryAddressData,
    onChangeDeliveryAddressData, pricing, onClickBackStep, onChangeExtraLuggage,
    extraLuggage, onClickPay, onChangeStep, validSteps, currentUser, paymentTypes,
    selectedPaymentType, onSelectPaymentType, transactionId, deliveryAddressDataErrors,
    onChangeTransactionId, contactDataErrors, shippingAddressDataErrors,
    isValidLuggageStep, onChangeTravelType, travelType, returnAddressData,
    onChangeReturnAddress, pickupAddressData, onChangePickupAddressData,
    restrictedDates, isAbleToChangeExtraLuggage } = props;
  const { t } = useTranslation();

  const stepTitles = {
    1: 'luggageStep',
    2: 'personalInformationStep',
    3: 'addressInformationStep',
    4: 'deliveryAddressOfTheAccommodationStep',
    5: 'validateInformation',
  };

  return (

    <div>
      {
        <Header
          typesOfLuggage={typesOfLuggage}
          extraLuggages={extraLuggages}
        />
      }
    <div className='container container_information'>
      <div className='row'>
        <div className='col-xs-12 gray-border pdb-10 font-green'>
          <div className='row'>
            <div className='col-xs-12 text-center'>
              <StepIndicator
                currentStep={currentStep}
                stepTitles={stepTitles}
                onChange={onChangeStep}
                validSteps={validSteps}
              />
            </div>
          </div>
          { currentStep === 1 &&
            <LuggageStep
              typesOfLuggage={typesOfLuggage}
              extraLuggages={extraLuggages}
              onClickNextStep={onClickNextStep}
              onChangeLuggageQuantity={onChangeLuggageQuantity}
              onChangeExtraLuggage={onChangeExtraLuggage}
              selectedLuggages={selectedLuggages}
              selectedExtraLuggageId={extraLuggage}
              onChangeTravelType={onChangeTravelType}
              travelType={travelType}
              isValid={isValidLuggageStep}
              isAbleToChangeExtraLuggage={isAbleToChangeExtraLuggage}
            />
          }
          { currentStep === 2 &&
              <ContactStep
                contactData={contactData}
                onChangeContact={onChangeContact}
                contactDataErrors={contactDataErrors}
              />
          }
          { currentStep === 3 &&
              <ShippingAddressStep
                onChangeShippingAddress={onChangeShippingAddress}
                shippingAddressData={shippingAddressData}
                shippingAddressDataErrors={shippingAddressDataErrors}
                returnAddressData={returnAddressData}
                onChangeReturnAddress={onChangeReturnAddress}
                travelType={travelType}
                countries={countries}
                restrictedDates={restrictedDates}
              />
          }
          { currentStep === 4 &&
              <DeliveryAddressStep
                pickupAddressData={pickupAddressData}
                countries={countries}
                deliveryAddressData={deliveryAddressData}
                onChangeDeliveryAddressData={onChangeDeliveryAddressData}
                deliveryAddressDataErrors={deliveryAddressDataErrors}
                onChangePickupAddressData={onChangePickupAddressData}
                travelType={travelType}
              />
          }
          { currentStep === 5 &&
              <CheckYourDataStep
                contactData={contactData}
                shippingAddressData={shippingAddressData}
                deliveryAddressData={deliveryAddressData}
                selectedLuggages={selectedLuggages}
                typesOfLuggage={typesOfLuggage}
                pricing={pricing}
                extraLuggages={extraLuggages}
                extraLuggage={extraLuggage}
                currentUser={currentUser}
                paymentTypes={paymentTypes}
                selectedPaymentType={selectedPaymentType}
                onSelectPaymentType={onSelectPaymentType}
                onChangeTransactionId={onChangeTransactionId}
                transactionId={transactionId}
                travelType={travelType}
                returnAddressData={returnAddressData}
                pickupAddressData={pickupAddressData}
              />
          }
          <StepButtons
            currentStep={currentStep}
            onClickNextStep={onClickNextStep}
            onClickBackStep={onClickBackStep}
            steps={steps}
            onClickPay={onClickPay}
            isValidLuggageStep={isValidLuggageStep}
          />
        </div>
      </div>
    </div>
  </div>
  )
}

BookingForm.propTypes = {
  typesOfLuggage: PropTypes.array,
  extraLuggages: PropTypes.array,
  currentStep: PropTypes.number,
  steps: PropTypes.array,
  onChangeLuggageQuantity: PropTypes.func,
  selectedLuggages: PropTypes.object,
  pricing: PropTypes.object,
  onClickNextStep: PropTypes.func.isRequired,
  onClickBackStep: PropTypes.func.isRequired,
  onClickPay: PropTypes.func.isRequired,
  onChangeExtraLuggage: PropTypes.func.isRequired,
  onChangeStep: PropTypes.func.isRequired,
  validSteps: PropTypes.array.isRequired,
  currentUser: PropTypes.object.isRequired,
  paymentTypes: PropTypes.array.isRequired,
  selectedPaymentType: PropTypes.object.isRequired,
  onSelectPaymentType: PropTypes.func.isRequired,
  transactionId: PropTypes.node.isRequired,
  onChangeTransactionId: PropTypes.func.isRequired,
  onChangeReturnAddress: PropTypes.func.isrequired,
  returnAddressData: PropTypes.object.isRequired,
  pickupAddressData: PropTypes.object.isRequired,
}

export default BookingForm;
