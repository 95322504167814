import React from 'react';
import PropTypes from 'prop-types';

function QuantitySelect(props) {
  const { quantity, className, onChange, value, entity } = props;

  let options = [];
  for (let i = 0; i < quantity; i++) {
    options.push(<option value={i} key={i}>{i}</option>);
  };

  const onChangeValue = (ev) => {
    onChange(entity, parseInt(ev.target.value));
  }

  return (
    <select className={className} onChange={onChangeValue} value={value}>
      {options}
    </select>
  )
}

QuantitySelect.propTypes = {
  quantity: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  entity: PropTypes.object,
  className: PropTypes.string,
}

export default QuantitySelect;
