import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
//import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationNL from './locales/nl/translation.json';
import translationES from './locales/es/translation.json';


// not like to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

const resources = {
  en: {
    translation: translationEN
  },
  nl: {
    translation: translationNL
  },
  es: {
    translation: translationES
  }
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  //.use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: "nl",
    resources: {
      en: {
          translation: translationEN               // 'translation' is our custom namespace
      },
      nl: {
          translation: translationNL
      },
      es: {
          translation: translationES
      },
    },
    fallbackLng: "en", // use en if detected lng is not available
    react: {
      useSuspense: false,
    }
    });




export default i18n;